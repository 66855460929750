angular.module("aerosApp").controller("TestSetupCtrl", [ "$scope", "aerosApi", "$state", "$stateParams", "$timeout", 'Notification', 'newTestSetupModal', 'fiberTypes', "$translate",
  function($scope, aerosApi, $state, $stateParams, $timeout, Notification, newTestSetupModal, fiberTypes, $translate) {
	$scope.$watch("commonReady", function (newVal) {
		if (!newVal) return;
//		if (!$scope.allow['viewTests']) return $scope.reject();
		$scope.fiberTypes = fiberTypes;
		$scope.filter = "";
		$scope.testSetups = [];
		$scope.messages = {
				"archiveSuccess": "Updated test setup",
				"archiveFail": "Failed to update setup",
				"copyOf": "Copy of",
				"createSuccess": "Test Setup created.",
				"createFailure": "Failed to create setup - Duplicate."
		};
		_.each($scope.messages, function (message,key) {
			$translate(message).then(function (translation) {
				$scope.messages[key]= translation;
			});
		});

		$scope.statusFilter = "active";
		function loadTestSetups(){
			aerosApi.getAllTestSetups($scope.organization.id)
			.then(function(setups){
				if (!$scope.allow['deactivateTests'] || $scope.statusFilter === "active") {
					setups = _.filter(setups, function (setup) {
						return !setup.archived;
					});
				} else if ($scope.statusFilter === "hidden") {
					setups = _.filter(setups, function (setup) {
						return setup.archived;
					});
				} // "both" won't reduce the list
				$scope.testSetups = setups;
			});		
		};
		$scope.updateStatusFilter = function (statusFilter) {
			$scope.statusFilter = statusFilter;
			loadTestSetups();
		};

		var api = {
			toggleArchived: function(setup) {
				if (!$scope.allow["deactivateTests"]) return;
				aerosApi.toggleTestSetupStatus(setup.key, $scope.organization.id).then(function(resp) {
					var ok = resp.status >= 200 && resp.status < 300;

					if (ok) {
						loadTestSetups();
						Notification.success($scope.messages['archiveSuccess'] + " '" + setup.name +"'.");
					}
					else {
						Notification.error($scope.messages['archiveFail'] + " '" + setup.name +"'.");
					}
				});
			},
			copySetup: function(setup) {
				if (!$scope.allow["createTests"]) return;
				var nameLength = 40;
				var newSetup = angular.copy(setup);
				delete newSetup.key;
				var tempName = $scope.messages['copyOf'] + " " + setup.name;
				newSetup.name = tempName.length > nameLength ? tempName.substring(0, nameLength) : tempName;
				var testTemplate = getTestTemplate(newSetup);
				newTestSetupModal.open(newSetup).then(function() {
					Notification.success($scope.messages['createSuccess']);
					loadTestSetups();
				});
			},
			createTestSetup: function(testTemplate) {
				if (!$scope.allow["createTests"]) return;
				newTestSetupModal.open({}).then(function(response) {
					if(response.code === 500)
					{
						// Currently 500 code means it is a duplicate test setup
						// TODO: In the future we can handle other codes and make a one that is specific to duplicate
						Notification.error($scope.messages['createFailure']);
					}else{
						Notification.success($scope.messages['createSuccess']);
						loadTestSetups();
					}
				});
			}
		};
		// Init
		loadTestSetups();
		angular.extend($scope, api);

		$scope.testTypeIconName = function(testSetup) {
			var testTypeIcons = {
				"OLTS": "loss",
				"Certification": "loss",
				"INSPECTION": "inspect",
				"ORL": "orl"
			};
			var directions = {
				"Unidirectional": "unidirectional",
				"Bidirectional": "bidirectional"
			};
			var iconName = testTypeIcons[testSetup.type];
			if(testSetup.direction){
				iconName += "-" + directions[testSetup.direction];
			}
			if(testSetup.rule){
				iconName += "-cert";
			}
			return iconName;
		};
	});
}]);